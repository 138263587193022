.Wrap {
  width: 100%;
  max-width: 1320px;

  margin: 0 auto;
  padding: 96px 0px;
}

.Wrap > * {
  margin: 0 96px;
}

.Title {
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: 600;
  color: #000;
}

.Sub {
  display: flex;
}

.Sub > *:nth-child(1) {
  margin-right: auto;
}

.Sub > *:nth-child(2) {
  margin-right: 0;
  margin-left: 0;
  margin-top: auto;
  margin-bottom: 32px;
}

.Description {
  margin-bottom: 32px;
  font-size: 20px;
  color: #000;
}

.Info {
  margin-top: 32px;
  font-size: 16px;
  color: rgb(85, 85, 85);
}

.Content {
  display: block;
}

.Content > img {
  display: block;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}

.Content > video {
  display: block;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1100px) {
  .Wrap {
    padding: 48px 0px;
  }
  .Wrap > * {
    margin: 0 16px;
  }
  .Info {
    margin-top: 32px;
  }
}

@media (max-width: 500px) {
  .Sub {
    flex-direction: column;
  }
  .Title {
    font-size: 30px;
  }
}
