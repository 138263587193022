.Selector {
  display: flex !important;

  cursor: pointer;
}

.Selector * {
  display: block;
  margin: auto 0;
}

.Selector img {
  height: 18px;
  margin-left: 8px;
}
