.Wrap {
}

.Item {
  font-size: 18px;
}

.Item:nth-child(2) {
  margin: 0 16px;
}

.Dot0,
.Dot1,
.Dot2 {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-left: 8px;

  border-radius: 50%;
  background-color: red;
}

.Item:nth-child(1) {
  color: var(--color-plan-personal);
}

.Item:nth-child(2) {
  color: var(--color-plan-team);
}

.Item:nth-child(3) {
  color: var(--color-plan-enterprise);
}

.Dot0 {
  background-color: var(--color-plan-personal);
}

.Dot1 {
  background-color: var(--color-plan-team);
}

.Dot2 {
  background-color: var(--color-plan-enterprise);
}
