.Hero {
  display: flex;
  position: relative;
  z-index: 0;
  min-height: 100vh;

  background-image: url("/branding/hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  /*background-color: #132730;*/
}

.Hero::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.Hero > * {
  position: relative;
  z-index: 1;
}

.Item {
  display: flex;
  margin: auto;
}

.Parts {
  margin: 0 32px;
  max-width: 35vw;

  padding: 16px;
}

.Title {
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

.Description {
  margin-bottom: 32px;
  text-align: justify;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

@media (max-width: 1400px) {
  .Parts {
    max-width: 45vw;
  }
}

@media (max-width: 1100px) {
  .Item {
    flex-direction: column-reverse;
  }
  .Parts {
    max-width: 100vw;
  }
  .Parts:nth-child(2) {
    text-align: center;
  }

  @media (max-height: 790px) {
    .Parts:nth-child(2) {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .Item {
    flex-direction: column-reverse;
  }
  .Parts:nth-child(2) {
    display: none;
  }
}

@media (max-width: 380px) {
  .Parts {
    margin: 0;
    padding: 16px;
  }
}
