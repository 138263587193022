.Wrap,
.WrapHidden {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 2;

  transition: 0.2s;
}

.Wrap::before,
.WrapHidden::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(to top right, #ff00aa, #8a38ca, #2d64e3);
  z-index: -1;
  opacity: 0.5;
}

.WrapHidden {
  pointer-events: none;
  opacity: 0;
}

.Wrap .Navigation {
  transform: translateX(0);
  transition: 0.2s;
}

.WrapHidden .Navigation {
  transform: translateX(100%);
  transition: 0.2s;
}

.Navigation {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 0;

  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;

  background-color: #fff;
  overflow-y: auto;
}

.Navigation ul li {
  display: block;
  list-style-type: none;
}

.Navigation ul li > * {
  display: block;
  list-style-type: none;
  margin-right: auto 0;
  padding: 22px 64px;
  border-bottom: 4px solid transparent;
}

.CloseBtn {
  margin-top: auto;
  margin-bottom: 16px;

  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;

  border: none;
  background-color: inherit;

  padding: 32px 0;
}
