.Wrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;

  background-color: #fff;
  z-index: 1;

  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.397);
}

.Info {
  background: var(--color-primary);

  text-align: center;
  font-size: 16px;
  padding: 2px 0px;

  color: #ffffffca;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Main {
  display: flex;
  margin: 0 auto;

  max-width: 1320px;
}

.Brand {
  display: flex;
  font-size: 30px;
  font-weight: 600;
  color: #000;
}

.Brand > * {
  margin: auto 0;
}

.Brand img {
  height: 32px;
  width: 32px;

  margin-right: 16px;
}

.Navigation {
  margin-left: auto;
  margin-right: 0;

  font-size: 18px;
  color: #000;
}

.Navigation ul li {
  display: inline-block;
  list-style-type: none;
}

.Navigation ul li > * {
  cursor: pointer;
  display: inline-block;
  list-style-type: none;
  margin-right: auto 0;
  padding: 22px 32px;
  border-bottom: 4px solid transparent;
}

.Navigation ul li .Selected {
  border-bottom: 4px solid var(--color-primary);
  color: var(--color-primary);
}

.MobileNavigationButton {
  display: none;

  margin-left: auto;
  margin-right: 0;

  padding: 16px 16px;
}

@media (max-width: 1380px) {
  .Main {
    padding: 0 16px;
  }
}

@media (max-width: 850px) {
  .Navigation {
    display: none;
  }
  .MobileNavigationButton {
    display: block;
  }
  .Main {
    padding: 0 16px;
    padding-right: 0;
  }
}

@media (max-width: 600px) {
  .Info {
    color: transparent;
    font-size: 0;
  }
}
