* {
  margin: 0;
  padding: 0;

  --color-primary: #00796b;
  --color-accent: #009688;

  --color-plan-personal: #009688;
  --color-plan-team: #c51162;
  --color-plan-enterprise: #333333;
}

body {
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #fff;
}
