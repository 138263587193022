.Wrap {
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3));
}

.Item {
  margin: 30px;
}

.ItemInnerWrap {
  position: relative;
}

.Item img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  backface-visibility: hidden;
  transition: 0.2s;
}

.Item:hover img {
  transform: rotateY(180deg);
}

.Details {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: rotateY(180deg);
  padding: 16px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  backface-visibility: hidden;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 22px;
  opacity: 0;
  transition: 0.2s;
}

.Item:hover .Details {
  opacity: 1;
  transform: rotateY(0deg);
}

.Item p {
  margin-top: 32px;

  font-size: 20px;
  font-weight: 600;
  text-align: center;

  color: var(--color-primary);
}

@media (max-width: 900px) {
  .Wrap {
    grid-template-columns: repeat(2, calc(100% / 2));
  }
}

@media (max-width: 500px) {
  .Wrap {
    grid-template-columns: 100%;
  }
  .Item {
    margin: 30px 0;
  }
}
