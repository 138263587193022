.Wrap {
  display: flex;
  margin: 32px 0;
}

.Item {
  flex: 1;
  text-align: center;
  color: #000;
}

.Title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.Info {
  font-size: 20px;
  line-height: 30px;
}

.Item:nth-child(1) .Title {
  color: var(--color-primary);
}

.Item:nth-child(2) .Title {
  color: var(--color-primary);
}

.Item:nth-child(3) .Title {
  color: var(--color-primary);
}

@media (max-width: 1000px) {
  .Wrap {
    flex-direction: column;
  }

  .Item {
    margin: 32px auto;
  }
}
