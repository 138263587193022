.Wrap {
  display: flex;
  width: 100%;
  min-height: 100px;

  background-color: #132730;
}

.Nav {
  margin: auto;
  color: rgb(185, 185, 185);
}

.Nav a {
  margin: 0 16px;
}

.Nav a:hover {
  color: #fff;
}

@media (max-width: 650px) {
  .Wrap {
    padding: 32px 0;
  }

  .Nav a {
    display: block;
    margin: 16px 0px;
    text-align: center;
  }
}
