.Wrap {
}

.Item {
  display: flex;
  font-size: 20px;
  color: #393939;
  padding: 8px 32px;

  border-top-style: solid;
  border-bottom-style: solid;

  border-width: 2px;
  border-color: #ececec;
  background-color: #fff;
  margin-bottom: 8px;
}

.ItemName {
  margin-right: auto;
}

.AvailabilityIndicator {
  display: flex;
}

.AvailabilityIndicator > div {
  width: 16px;
  height: 16px;

  border-radius: 50%;
  box-sizing: border-box;
  border-style: solid;
  border-width: 2px;
  opacity: 0.7;
}

.AvailabilityIndicator > div:nth-child(1) {
  border-color: var(--color-plan-personal);
}

.AvailabilityIndicator > div:nth-child(2) {
  margin: 0 32px;
  border-color: var(--color-plan-team);
}

.AvailabilityIndicator > div:nth-child(3) {
  border-color: var(--color-plan-enterprise);
}

.AvailabilityIndicator > div.Active:nth-child(1) {
  background-color: var(--color-plan-personal);
  opacity: 1;
}

.AvailabilityIndicator > div.Active:nth-child(2) {
  background-color: var(--color-plan-team);
  opacity: 1;
}

.AvailabilityIndicator > div.Active:nth-child(3) {
  background-color: var(--color-plan-enterprise);
  opacity: 1;
}

@media (max-width: 600px) {
  .AvailabilityIndicator > div:nth-child(2) {
    margin: 0 16px;
  }
}

@media (max-width: 500px) {
  .AvailabilityIndicator > div:nth-child(2) {
    margin: 0 8px;
  }
}

@media (max-width: 350px) {
  .AvailabilityIndicator {
    flex-direction: column;
  }
  .AvailabilityIndicator > div:nth-child(2) {
    margin: 8px 0px;
  }
  .AvailabilityIndicator > div {
    width: 8px;
    height: 8px;
    opacity: 0.4;
  }
  .AvailabilityIndicator > div.Active {
    opacity: 1;
  }
  .AvailabilityIndicator {
    margin-left: 8px;
  }
}
