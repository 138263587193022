.OuterWrap {
  display: block;
}

.Wrap {
  display: flex;

  margin: 0 auto;
  max-width: 800px;
}

.Input {
  flex: 1;
  margin-left: 0;
  margin-right: 16px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-accent);
  border-radius: 5px;
  font-size: 20px;
  padding: 6px 18px;
}

.Wrap button {
  margin: 0;
  background-color: var(--color-accent);
}

.ErrorMessage {
  color: red;
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
}

.SuccessMessage {
  color: var(--color-primary);
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  padding: 6px 18px;
}

@media (max-width: 500px) {
  .Wrap {
    flex-direction: column;
  }
  .Input {
    flex: 1;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 16px;
  }
}
