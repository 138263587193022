.Wrap,
.WrapHidden {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 4;

  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  transition: 0.2s;
}

.WrapHidden {
  opacity: 0;
  pointer-events: none;
}

.Panel {
  margin: auto;
  padding: 16px 64px;
  border-radius: 5px;

  background-color: #fff;
}

.Item,
.ItemClose {
  text-align: center;
  padding: 16px 0;
  font-size: 20px;
  cursor: pointer;
}

.Item:hover,
.ItemClose:hover {
  color: #f700ff;
}

.Panel hr {
  margin: 16px 0;
}

.Title {
  font-size: 24px !important;
  font-weight: 600;
  color: #000;
  margin-bottom: 16px;
}

@media (min-width: 900px) {
  .Panel {
    margin: auto;
    padding: 32px 256px;

    background-color: #fff;
  }
}

@media (min-width: 1000px) {
  .Wrap,
  .WrapHidden {
    background-color: transparent;
  }

  .Panel {
    position: fixed;
    top: 70px;
    padding: 16px 32px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.397);
  }

  .Title,
  .ItemClose,
  .Panel hr {
    display: none;
  }
}
