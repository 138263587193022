.Wrap {
  display: flex;
}

.Item {
  flex: 1;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);

  color: #000;
}

.Head {
  padding: 20px 0;
  text-align: center;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.Price {
  margin-top: 25px;

  font-size: 26px;
  text-align: center;
}

.PerUser {
  font-size: 18px;
  margin-top: 8px;
  text-align: center;
}

.Features {
  margin-top: 32px;
  padding: 0 32px;
}

.Features > span {
  display: block;
  margin: 16px 0;

  font-size: 18px;
}

.Features > span:nth-child(even) {
  color: rgb(82, 82, 82);
}

.Features > span > span:nth-child(2) {
  float: right;
}

.Item button {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  margin-bottom: 16px;
}

/* Head */
.Wrap > .Item:nth-child(1) .Head {
  background-color: var(--color-plan-personal);
}

.Wrap > .Item:nth-child(2) .Head {
  background-color: var(--color-plan-team);
}

.Wrap > .Item:nth-child(3) .Head {
  background-color: var(--color-plan-enterprise);
}

/* Buttons */
.Wrap > .Item:nth-child(1) button {
  background-color: var(--color-plan-personal);
}
.Wrap > .Item:nth-child(2) button {
  background-color: var(--color-plan-team);
}
.Wrap > .Item:nth-child(3) button {
  background-color: var(--color-plan-enterprise);
}

@media (min-width: 1000px) {
  /* Item */
  .Wrap > .Item:nth-child(1) {
    margin: 20px 0;
    border-bottom-right-radius: 0px;
  }

  .Wrap > .Item:nth-child(3) {
    margin: 20px 0;
    border-bottom-left-radius: 0px;
  }

  /* Head */
  .Wrap > .Item:nth-child(1) .Head {
    border-top-right-radius: 0px;
  }
  .Wrap > .Item:nth-child(3) .Head {
    border-top-left-radius: 0px;
  }
}

@media (max-width: 1000px) {
  .Wrap {
    flex-direction: column;
  }

  .Item {
    width: 100%;
    max-width: 500px;
    margin: 16px auto;
  }
}
