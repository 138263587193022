/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  outline: 0;
}

* {
  margin: 0;
  padding: 0;

  --color-primary: #00796b;
  --color-accent: #009688;

  --color-plan-personal: #009688;
  --color-plan-team: #c51162;
  --color-plan-enterprise: #333333;
}

body {
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #fff;
}

.Header_Wrap__3wI7s {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;

  background-color: #fff;
  z-index: 1;

  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.397);
}

.Header_Info__3eAV2 {
  background: var(--color-primary);

  text-align: center;
  font-size: 16px;
  padding: 2px 0px;

  color: #ffffffca;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Header_Main__2Q140 {
  display: flex;
  margin: 0 auto;

  max-width: 1320px;
}

.Header_Brand__3W2KU {
  display: flex;
  font-size: 30px;
  font-weight: 600;
  color: #000;
}

.Header_Brand__3W2KU > * {
  margin: auto 0;
}

.Header_Brand__3W2KU img {
  height: 32px;
  width: 32px;

  margin-right: 16px;
}

.Header_Navigation__2Q6Pd {
  margin-left: auto;
  margin-right: 0;

  font-size: 18px;
  color: #000;
}

.Header_Navigation__2Q6Pd ul li {
  display: inline-block;
  list-style-type: none;
}

.Header_Navigation__2Q6Pd ul li > * {
  cursor: pointer;
  display: inline-block;
  list-style-type: none;
  margin-right: auto 0;
  padding: 22px 32px;
  border-bottom: 4px solid transparent;
}

.Header_Navigation__2Q6Pd ul li .Header_Selected__1B8L- {
  border-bottom: 4px solid var(--color-primary);
  color: var(--color-primary);
}

.Header_MobileNavigationButton__3Bbjm {
  display: none;

  margin-left: auto;
  margin-right: 0;

  padding: 16px 16px;
}

@media (max-width: 1380px) {
  .Header_Main__2Q140 {
    padding: 0 16px;
  }
}

@media (max-width: 850px) {
  .Header_Navigation__2Q6Pd {
    display: none;
  }
  .Header_MobileNavigationButton__3Bbjm {
    display: block;
  }
  .Header_Main__2Q140 {
    padding: 0 16px;
    padding-right: 0;
  }
}

@media (max-width: 600px) {
  .Header_Info__3eAV2 {
    color: transparent;
    font-size: 0;
  }
}

.MobileNavigationMenu_Wrap__22BnM,
.MobileNavigationMenu_WrapHidden__31IoA {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 2;

  -webkit-transition: 0.2s;

  transition: 0.2s;
}

.MobileNavigationMenu_Wrap__22BnM::before,
.MobileNavigationMenu_WrapHidden__31IoA::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: -webkit-gradient(linear, left bottom, right top, from(#ff00aa), color-stop(#8a38ca), to(#2d64e3));

  background: -webkit-linear-gradient(bottom left, #ff00aa, #8a38ca, #2d64e3);

  background: linear-gradient(to top right, #ff00aa, #8a38ca, #2d64e3);
  z-index: -1;
  opacity: 0.5;
}

.MobileNavigationMenu_WrapHidden__31IoA {
  pointer-events: none;
  opacity: 0;
}

.MobileNavigationMenu_Wrap__22BnM .MobileNavigationMenu_Navigation__1F1_n {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.MobileNavigationMenu_WrapHidden__31IoA .MobileNavigationMenu_Navigation__1F1_n {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.MobileNavigationMenu_Navigation__1F1_n {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 0;

  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;

  background-color: #fff;
  overflow-y: auto;
}

.MobileNavigationMenu_Navigation__1F1_n ul li {
  display: block;
  list-style-type: none;
}

.MobileNavigationMenu_Navigation__1F1_n ul li > * {
  display: block;
  list-style-type: none;
  margin-right: auto 0;
  padding: 22px 64px;
  border-bottom: 4px solid transparent;
}

.MobileNavigationMenu_CloseBtn__15DNU {
  margin-top: auto;
  margin-bottom: 16px;

  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;

  border: none;
  background-color: inherit;

  padding: 32px 0;
}

.LangSelector_Selector__2Tq-_ {
  display: flex !important;

  cursor: pointer;
}

.LangSelector_Selector__2Tq-_ * {
  display: block;
  margin: auto 0;
}

.LangSelector_Selector__2Tq-_ img {
  height: 18px;
  margin-left: 8px;
}

.LangSelectDialog_Wrap__2g3Bl,
.LangSelectDialog_WrapHidden__2Zwsr {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 4;

  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  -webkit-transition: 0.2s;

  transition: 0.2s;
}

.LangSelectDialog_WrapHidden__2Zwsr {
  opacity: 0;
  pointer-events: none;
}

.LangSelectDialog_Panel__1fsmW {
  margin: auto;
  padding: 16px 64px;
  border-radius: 5px;

  background-color: #fff;
}

.LangSelectDialog_Item__n2ppY,
.LangSelectDialog_ItemClose__X2wf7 {
  text-align: center;
  padding: 16px 0;
  font-size: 20px;
  cursor: pointer;
}

.LangSelectDialog_Item__n2ppY:hover,
.LangSelectDialog_ItemClose__X2wf7:hover {
  color: #f700ff;
}

.LangSelectDialog_Panel__1fsmW hr {
  margin: 16px 0;
}

.LangSelectDialog_Title__7EzZd {
  font-size: 24px !important;
  font-weight: 600;
  color: #000;
  margin-bottom: 16px;
}

@media (min-width: 900px) {
  .LangSelectDialog_Panel__1fsmW {
    margin: auto;
    padding: 32px 256px;

    background-color: #fff;
  }
}

@media (min-width: 1000px) {
  .LangSelectDialog_Wrap__2g3Bl,
  .LangSelectDialog_WrapHidden__2Zwsr {
    background-color: transparent;
  }

  .LangSelectDialog_Panel__1fsmW {
    position: fixed;
    top: 70px;
    padding: 16px 32px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.397);
  }

  .LangSelectDialog_Title__7EzZd,
  .LangSelectDialog_ItemClose__X2wf7,
  .LangSelectDialog_Panel__1fsmW hr {
    display: none;
  }
}

.Hero_Hero__jiLCG {
  display: flex;
  position: relative;
  z-index: 0;
  min-height: 100vh;

  background-image: url("/branding/hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  /*background-color: #132730;*/
}

.Hero_Hero__jiLCG::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.Hero_Hero__jiLCG > * {
  position: relative;
  z-index: 1;
}

.Hero_Item__1B6o0 {
  display: flex;
  margin: auto;
}

.Hero_Parts__3rukk {
  margin: 0 32px;
  max-width: 35vw;

  padding: 16px;
}

.Hero_Title__3ekTg {
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

.Hero_Description__20gjJ {
  margin-bottom: 32px;
  text-align: justify;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

@media (max-width: 1400px) {
  .Hero_Parts__3rukk {
    max-width: 45vw;
  }
}

@media (max-width: 1100px) {
  .Hero_Item__1B6o0 {
    flex-direction: column-reverse;
  }
  .Hero_Parts__3rukk {
    max-width: 100vw;
  }
  .Hero_Parts__3rukk:nth-child(2) {
    text-align: center;
  }

  @media (max-height: 790px) {
    .Hero_Parts__3rukk:nth-child(2) {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .Hero_Item__1B6o0 {
    flex-direction: column-reverse;
  }
  .Hero_Parts__3rukk:nth-child(2) {
    display: none;
  }
}

@media (max-width: 380px) {
  .Hero_Parts__3rukk {
    margin: 0;
    padding: 16px;
  }
}

.Button_Button__T16Hz {
  padding: 8px 32px;
  margin: auto;

  border: none;
  border-radius: 5px;

  font-size: 20px;
  font-weight: 600;
  color: #fff;

  cursor: pointer;

  background-color: var(--color-accent);

  -webkit-transition: 0.1s;

  transition: 0.1s;
}

.Button_Button__T16Hz:hover {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .Button_Button__T16Hz {
    font-size: 16px;
    padding: 8px 16px;
  }
}

.ContentItem_Wrap__3d92m {
  width: 100%;
  max-width: 1320px;

  margin: 0 auto;
  padding: 96px 0px;
}

.ContentItem_Wrap__3d92m > * {
  margin: 0 96px;
}

.ContentItem_Title__6tJDe {
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: 600;
  color: #000;
}

.ContentItem_Sub__3lM3Y {
  display: flex;
}

.ContentItem_Sub__3lM3Y > *:nth-child(1) {
  margin-right: auto;
}

.ContentItem_Sub__3lM3Y > *:nth-child(2) {
  margin-right: 0;
  margin-left: 0;
  margin-top: auto;
  margin-bottom: 32px;
}

.ContentItem_Description__1Ely0 {
  margin-bottom: 32px;
  font-size: 20px;
  color: #000;
}

.ContentItem_Info__1l6Bg {
  margin-top: 32px;
  font-size: 16px;
  color: rgb(85, 85, 85);
}

.ContentItem_Content__1U5j6 {
  display: block;
}

.ContentItem_Content__1U5j6 > img {
  display: block;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}

.ContentItem_Content__1U5j6 > video {
  display: block;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1100px) {
  .ContentItem_Wrap__3d92m {
    padding: 48px 0px;
  }
  .ContentItem_Wrap__3d92m > * {
    margin: 0 16px;
  }
  .ContentItem_Info__1l6Bg {
    margin-top: 32px;
  }
}

@media (max-width: 500px) {
  .ContentItem_Sub__3lM3Y {
    flex-direction: column;
  }
  .ContentItem_Title__6tJDe {
    font-size: 30px;
  }
}

.Footer_Wrap__2B9JF {
  display: flex;
  width: 100%;
  min-height: 100px;

  background-color: #132730;
}

.Footer_Nav__B5ht4 {
  margin: auto;
  color: rgb(185, 185, 185);
}

.Footer_Nav__B5ht4 a {
  margin: 0 16px;
}

.Footer_Nav__B5ht4 a:hover {
  color: #fff;
}

@media (max-width: 650px) {
  .Footer_Wrap__2B9JF {
    padding: 32px 0;
  }

  .Footer_Nav__B5ht4 a {
    display: block;
    margin: 16px 0px;
    text-align: center;
  }
}

.FeatureList_Wrap__nU525 {
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3));
}

.FeatureList_Item__3JzE- {
  margin: 30px;
}

.FeatureList_ItemInnerWrap__3FKs2 {
  position: relative;
}

.FeatureList_Item__3JzE- img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.FeatureList_Item__3JzE-:hover img {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.FeatureList_Details__1NIaS {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  padding: 16px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 22px;
  opacity: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.FeatureList_Item__3JzE-:hover .FeatureList_Details__1NIaS {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}

.FeatureList_Item__3JzE- p {
  margin-top: 32px;

  font-size: 20px;
  font-weight: 600;
  text-align: center;

  color: var(--color-primary);
}

@media (max-width: 900px) {
  .FeatureList_Wrap__nU525 {
    grid-template-columns: repeat(2, calc(100% / 2));
  }
}

@media (max-width: 500px) {
  .FeatureList_Wrap__nU525 {
    grid-template-columns: 100%;
  }
  .FeatureList_Item__3JzE- {
    margin: 30px 0;
  }
}

.ContactInfo_Wrap__oZWYB {
  display: flex;
  margin: 32px 0;
}

.ContactInfo_Item__1-0xh {
  flex: 1 1;
  text-align: center;
  color: #000;
}

.ContactInfo_Title__1XSAC {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.ContactInfo_Info__2mWhn {
  font-size: 20px;
  line-height: 30px;
}

.ContactInfo_Item__1-0xh:nth-child(1) .ContactInfo_Title__1XSAC {
  color: var(--color-primary);
}

.ContactInfo_Item__1-0xh:nth-child(2) .ContactInfo_Title__1XSAC {
  color: var(--color-primary);
}

.ContactInfo_Item__1-0xh:nth-child(3) .ContactInfo_Title__1XSAC {
  color: var(--color-primary);
}

@media (max-width: 1000px) {
  .ContactInfo_Wrap__oZWYB {
    flex-direction: column;
  }

  .ContactInfo_Item__1-0xh {
    margin: 32px auto;
  }
}

.PricingList_Wrap__141cC {
  display: flex;
}

.PricingList_Item__17T9Y {
  flex: 1 1;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);

  color: #000;
}

.PricingList_Head__1mYgI {
  padding: 20px 0;
  text-align: center;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.PricingList_Price__GmGiw {
  margin-top: 25px;

  font-size: 26px;
  text-align: center;
}

.PricingList_PerUser__3iNcX {
  font-size: 18px;
  margin-top: 8px;
  text-align: center;
}

.PricingList_Features__9yHME {
  margin-top: 32px;
  padding: 0 32px;
}

.PricingList_Features__9yHME > span {
  display: block;
  margin: 16px 0;

  font-size: 18px;
}

.PricingList_Features__9yHME > span:nth-child(even) {
  color: rgb(82, 82, 82);
}

.PricingList_Features__9yHME > span > span:nth-child(2) {
  float: right;
}

.PricingList_Item__17T9Y button {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  margin-bottom: 16px;
}

/* Head */
.PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(1) .PricingList_Head__1mYgI {
  background-color: var(--color-plan-personal);
}

.PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(2) .PricingList_Head__1mYgI {
  background-color: var(--color-plan-team);
}

.PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(3) .PricingList_Head__1mYgI {
  background-color: var(--color-plan-enterprise);
}

/* Buttons */
.PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(1) button {
  background-color: var(--color-plan-personal);
}
.PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(2) button {
  background-color: var(--color-plan-team);
}
.PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(3) button {
  background-color: var(--color-plan-enterprise);
}

@media (min-width: 1000px) {
  /* Item */
  .PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(1) {
    margin: 20px 0;
    border-bottom-right-radius: 0px;
  }

  .PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(3) {
    margin: 20px 0;
    border-bottom-left-radius: 0px;
  }

  /* Head */
  .PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(1) .PricingList_Head__1mYgI {
    border-top-right-radius: 0px;
  }
  .PricingList_Wrap__141cC > .PricingList_Item__17T9Y:nth-child(3) .PricingList_Head__1mYgI {
    border-top-left-radius: 0px;
  }
}

@media (max-width: 1000px) {
  .PricingList_Wrap__141cC {
    flex-direction: column;
  }

  .PricingList_Item__17T9Y {
    width: 100%;
    max-width: 500px;
    margin: 16px auto;
  }
}

.MailingList_OuterWrap__3arfC {
  display: block;
}

.MailingList_Wrap__38ARu {
  display: flex;

  margin: 0 auto;
  max-width: 800px;
}

.MailingList_Input__2kx1M {
  flex: 1 1;
  margin-left: 0;
  margin-right: 16px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-accent);
  border-radius: 5px;
  font-size: 20px;
  padding: 6px 18px;
}

.MailingList_Wrap__38ARu button {
  margin: 0;
  background-color: var(--color-accent);
}

.MailingList_ErrorMessage__190WM {
  color: red;
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
}

.MailingList_SuccessMessage__1HT0x {
  color: var(--color-primary);
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  padding: 6px 18px;
}

@media (max-width: 500px) {
  .MailingList_Wrap__38ARu {
    flex-direction: column;
  }
  .MailingList_Input__2kx1M {
    flex: 1 1;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.FullFeatureList_Wrap__2ztpa {
}

.FullFeatureList_Item__3-S2g {
  display: flex;
  font-size: 20px;
  color: #393939;
  padding: 8px 32px;

  border-top-style: solid;
  border-bottom-style: solid;

  border-width: 2px;
  border-color: #ececec;
  background-color: #fff;
  margin-bottom: 8px;
}

.FullFeatureList_ItemName__38KWg {
  margin-right: auto;
}

.FullFeatureList_AvailabilityIndicator__3xJZT {
  display: flex;
}

.FullFeatureList_AvailabilityIndicator__3xJZT > div {
  width: 16px;
  height: 16px;

  border-radius: 50%;
  box-sizing: border-box;
  border-style: solid;
  border-width: 2px;
  opacity: 0.7;
}

.FullFeatureList_AvailabilityIndicator__3xJZT > div:nth-child(1) {
  border-color: var(--color-plan-personal);
}

.FullFeatureList_AvailabilityIndicator__3xJZT > div:nth-child(2) {
  margin: 0 32px;
  border-color: var(--color-plan-team);
}

.FullFeatureList_AvailabilityIndicator__3xJZT > div:nth-child(3) {
  border-color: var(--color-plan-enterprise);
}

.FullFeatureList_AvailabilityIndicator__3xJZT > div.FullFeatureList_Active__2jCQI:nth-child(1) {
  background-color: var(--color-plan-personal);
  opacity: 1;
}

.FullFeatureList_AvailabilityIndicator__3xJZT > div.FullFeatureList_Active__2jCQI:nth-child(2) {
  background-color: var(--color-plan-team);
  opacity: 1;
}

.FullFeatureList_AvailabilityIndicator__3xJZT > div.FullFeatureList_Active__2jCQI:nth-child(3) {
  background-color: var(--color-plan-enterprise);
  opacity: 1;
}

@media (max-width: 600px) {
  .FullFeatureList_AvailabilityIndicator__3xJZT > div:nth-child(2) {
    margin: 0 16px;
  }
}

@media (max-width: 500px) {
  .FullFeatureList_AvailabilityIndicator__3xJZT > div:nth-child(2) {
    margin: 0 8px;
  }
}

@media (max-width: 350px) {
  .FullFeatureList_AvailabilityIndicator__3xJZT {
    flex-direction: column;
  }
  .FullFeatureList_AvailabilityIndicator__3xJZT > div:nth-child(2) {
    margin: 8px 0px;
  }
  .FullFeatureList_AvailabilityIndicator__3xJZT > div {
    width: 8px;
    height: 8px;
    opacity: 0.4;
  }
  .FullFeatureList_AvailabilityIndicator__3xJZT > div.FullFeatureList_Active__2jCQI {
    opacity: 1;
  }
  .FullFeatureList_AvailabilityIndicator__3xJZT {
    margin-left: 8px;
  }
}

.PlansLegend_Wrap__18RYa {
}

.PlansLegend_Item__UyEAn {
  font-size: 18px;
}

.PlansLegend_Item__UyEAn:nth-child(2) {
  margin: 0 16px;
}

.PlansLegend_Dot0__1ktil,
.PlansLegend_Dot1__2heLu,
.PlansLegend_Dot2__1dGvw {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-left: 8px;

  border-radius: 50%;
  background-color: red;
}

.PlansLegend_Item__UyEAn:nth-child(1) {
  color: var(--color-plan-personal);
}

.PlansLegend_Item__UyEAn:nth-child(2) {
  color: var(--color-plan-team);
}

.PlansLegend_Item__UyEAn:nth-child(3) {
  color: var(--color-plan-enterprise);
}

.PlansLegend_Dot0__1ktil {
  background-color: var(--color-plan-personal);
}

.PlansLegend_Dot1__2heLu {
  background-color: var(--color-plan-team);
}

.PlansLegend_Dot2__1dGvw {
  background-color: var(--color-plan-enterprise);
}

