.Button {
  padding: 8px 32px;
  margin: auto;

  border: none;
  border-radius: 5px;

  font-size: 20px;
  font-weight: 600;
  color: #fff;

  cursor: pointer;

  background-color: var(--color-accent);

  transition: 0.1s;
}

.Button:hover {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .Button {
    font-size: 16px;
    padding: 8px 16px;
  }
}
